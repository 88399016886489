<template>
  <div>
    <img :src="item" v-for="(item, index) in list.image_list" :key="index" />
  </div>
</template>

<script>
import { get } from "@get/http.js";
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getIndex();
  },
  methods: {
    async getIndex() {
      let result = await get("/Inspection/show", {
        params: { store_id: 2, id: this.$route.query.r_id },
      });
      this.list = result;

      if (result.image_list?.[0].indexOf(".pdf") != 0) {
        location.replace(result.image_list[0]);
      }
      document.title = result.title;
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
</style>
